var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex flex-column",attrs:{"width":"285px"}},[_c('v-card-title',{staticClass:"d-flex flex-nowrap text-h6 py-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-overflow-ellipsis"},on),[_vm._v(" "+_vm._s(_vm.plan.label)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.plan.label)+" ")]),_c('v-spacer'),_c('v-chip',{staticClass:"ml-5 py-5",attrs:{"color":"app-red","dark":"","outlined":""}},[(_vm.plan.baseprice)?[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(_vm.plan.baseprice))+" TTC "),(_vm.plan.vat)?[_c('br'),_vm._v(" TVA "+_vm._s(_vm.plan.vat)+" % ")]:_vm._e()]:[_vm._v(" Inclus ")]],2)],1),_c('v-card-text',{staticClass:"flex-grow-1"},[_vm._l((_vm.plan.items),function(item){return [_c('v-tooltip',{key:item,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('p',_vm._g({staticClass:"text-overflow-ellipsis mb-0"},on),[_vm._v(" - "+_vm._s(item)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item)+" ")])]}),(_vm.plan.rooms_list.length > 0)?_c('v-divider',{staticClass:"mx-5 my-2"}):_vm._e(),_vm._l((_vm.plan.rooms_list),function(room){return [_c('v-chip',{key:room + 'room',staticClass:"mr-2",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.getProductRoom(room).label)+" ")])]})],2),_c('v-card-actions',{staticStyle:{"min-height":"44px"}},[_c('div',[_c('v-menu',{attrs:{"open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.plan.programs.length > 0)?_c('v-chip',_vm._g({class:{ 'mb-2': _vm.plan.sales.length > 0 },attrs:{"small":"","label":""}},on),[_vm._v(" "+_vm._s(_vm.plan.programs.length)+" "+_vm._s(_vm._f("plural")(_vm.plan.programs.length,'programme','programmes'))+" ")]):_vm._e()]}}],null,true)},[_c('v-card',[_c('v-list',_vm._l((_vm.plan.programs_list),function(program){return _c('v-list-item',{key:program.id},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":program.photo}})],1),_c('v-list-item-content',[_c('v-list-item-title',[(program.title)?_c('span',[_vm._v(" "+_vm._s(program.title)+" ")]):_c('span',[_c('i',[_vm._v(" Sans nom ")])])]),_c('v-list-item-subtitle',[(program.city)?_c('span',[_vm._v(" "+_vm._s(program.city)+" ")]):_vm._e(),(program.zip)?_c('span',[_vm._v(" ("+_vm._s(program.zip)+") ")]):_vm._e()])],1)],1)}),1)],1)],1),(_vm.plan.programs.length > 0 && _vm.plan.sales.length > 0)?_c('br'):_vm._e(),(_vm.plan.assets.length > 0)?_c('v-chip',{staticClass:"ml-2",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.plan.assets.length)+" "),_c('v-icon',{attrs:{"x-small":"","right":""}},[_vm._v(" fas fa-file ")])],1):_vm._e()],1),_c('v-spacer'),_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"rounded",attrs:{"color":"white"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"accent","small":"","icon":""}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-clone ")])],1)]}}],null,true)},[_vm._v(" Dupliquer ")]),_c('SOTUpdatePlanDialog',{attrs:{"plan":_vm.plan},on:{"updated":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"app-blue","small":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Modifier ")])]}}],null,true)}),_c('SOTDeletePlanDialog',{attrs:{"planId":_vm.plan.id},on:{"deleted":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","small":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash-alt ")])],1)]}}],null,true)},[_vm._v(" Supprimer ")])]}}],null,true)})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }