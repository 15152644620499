<template>
    <div class="ma-4">
        Définissez ici des modèles de lots, composés d'un ensemble de modèles de pièces. <br>
        Les lots réels objet des acquisitions sont ensuite associés à ces modèle de lot pour automatiquement proposer tous les choix possibles de personnalisation par pièce.

        <div class="d-flex my-2">
            <v-text-field class="flex-grow-0 mr-2" label="Recherche" outlined hide-details dense>
                <template v-slot:prepend-inner>
                    <v-icon left> fas fa-search </v-icon>
                </template>
            </v-text-field>

            <v-select class="flex-grow-0 mr-2" label="Type de lot" outlined hide-details dense />

            <v-spacer />

            <SOTProductLineProductModelAddDialog>
                <template v-slot:activator="{ on }">
                    <v-btn class="mr-2" color="app-blue" depressed small dark v-on="on">
                        <v-icon x-small left> fas fa-plus </v-icon>
                        Ajouter
                    </v-btn>
                </template>
            </SOTProductLineProductModelAddDialog>
        </div>

        <v-row>
            <v-col cols="2" v-for="(model, i) of models" :key="i">
                <v-hover v-slot="{ hover }">
                    <v-card class="d-flex flex-column fill-height">
                        <v-card-title class="d-flex flex-nowrap text-h6 py-2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span class="card-title" v-on="on">
                                        {{ model.label }}
                                    </span>
                                </template>
                                {{ model.label }}
                            </v-tooltip>

                            <v-spacer />

                            <v-icon v-if="model.checked" color="app-green" small> fas fa-check </v-icon>
                        </v-card-title>

                        <v-divider />

                        <v-card-text class="flex-grow-1 pa-0">
                            <v-list dense>
                                <v-list-item v-for="(item, i) of model.items" :key="i" style="min-height: 20px">
                                    <v-list-item-content class="py-1">
                                        <v-list-item-title>
                                            {{ item }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>

                        <v-card-actions style="min-height: 44px">
                            <v-chip v-if="model.type" label small>
                                {{ model.type }}
                            </v-chip>

                            <v-spacer />

                            <v-sheet v-show="hover" class="rounded" color="white">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn class="mr-1" color="accent" small icon v-on="{...tooltip }">
                                            <v-icon small> fas fa-clone </v-icon>
                                        </v-btn>
                                    </template>
                                    Dupliquer
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn class="mr-1" color="app-blue" small icon v-on="{...tooltip }">
                                            <v-icon small> fas fa-edit </v-icon>
                                        </v-btn>
                                    </template>
                                    Modifier
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn class="mr-1" color="red" small icon v-on="{...tooltip }">
                                            <v-icon small> fas fa-trash-alt </v-icon>
                                        </v-btn>
                                    </template>
                                    Supprimer
                                </v-tooltip>
                            </v-sheet>
                        </v-card-actions>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import SOTProductLineProductModelAddDialog from './SOTProductLineProductModelAddDialog.vue';

export default {
    name: 'SOTProductLineProductModels',

    props: {
        productLine: { type: Object, required: true }
    },

    components: {
        SOTProductLineProductModelAddDialog
    },

    data: () => ({
        models: [
            {
                label: '2 pièces - 1',
                checked: true,
                items: ['Salon', 'Chambre', 'Cuisine', 'Salle de bain', 'WC', 'Entrée', 'Couloir'],
                type: 'T2'
            },
            {
                label: '2 pièces - 2',
                checked: false,
                items: ['Salon', 'Chambre', 'Cuisine', 'Salle de bain / WC', 'WC', 'Entrée'],
                type: ''
            },
            {
                label: '3 pièces',
                checked: true,
                items: ['Salon', 'Chambre', 'Chambre', 'Cuisine', 'Salle de bain', 'WC', 'Entrée'],
                type: 'T3'
            }
        ]
    })
};
</script>