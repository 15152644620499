var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Nouvelle alternative TMA ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-text',[_c('ValidationObserver',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Libellé ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Libellé","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.plan.label),callback:function ($$v) {_vm.$set(_vm.plan, "label", $$v)},expression:"plan.label"}})]}}])})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Montant TTC ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Montant TTC","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":"","append-icon":"fas fa-euro-sign"},model:{value:(_vm.plan.baseprice),callback:function ($$v) {_vm.$set(_vm.plan, "baseprice", $$v)},expression:"plan.baseprice"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Programmes ")]),_c('v-col',[_c('ProgramAutocomplete',{attrs:{"multiple":""},model:{value:(_vm.plan.programs),callback:function ($$v) {_vm.$set(_vm.plan, "programs", $$v)},expression:"plan.programs"}})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" TVA ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"TVA","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('VATSelect',{attrs:{"label":"","error-messages":errors,"hide-details":!failed},model:{value:(_vm.plan.vat),callback:function ($$v) {_vm.$set(_vm.plan, "vat", $$v)},expression:"plan.vat"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Typologies ")])]),_c('v-col',[_c('ProductRoomsSelect',{attrs:{"multiple":""},model:{value:(_vm.plan.rooms_list),callback:function ($$v) {_vm.$set(_vm.plan, "rooms_list", $$v)},expression:"plan.rooms_list"}})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Travaux ")]),_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"flex-grow-0 mr-2",attrs:{"hide-details":"","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem()}},model:{value:(_vm.newItem),callback:function ($$v) {_vm.newItem=$$v},expression:"newItem"}}),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.addItem()}}},[_vm._v(" Ajouter ")])],1)])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.plan.items),function(item){return _c('v-chip',{key:item},[_vm._v(" "+_vm._s(item)+" "),_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.removeItem(item)}}},[_c('v-icon',{attrs:{"icon":"","x-small":""}},[_vm._v(" fas fa-times ")])],1)],1)}),1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Enregistrer ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }