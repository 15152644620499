<template>
    <v-select v-model="selectedRooms" :items="$store.state.products.rooms" :loading="isLoading" :disabled="disabled" item-value="key" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedRooms)" :class="{ 'flex-grow-0': flexGrow0 }" :multiple="multiple" :label="label" :placeholder="placeholder" background-color="white" hide-details no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" color="accent" small label dark>
                {{ data.item.label }}
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedRooms.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    <v-chip color="accent" label small dark>
                        {{ item.label }}
                    </v-chip>
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-select>
</template>

<script>
import productsMixin from '../../mixins/products.js';

export default {
    name: 'ProductRoomsSelect',

    mixins: [productsMixin],

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        flexGrow0: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false }
    },

    data: () => ({
        selectedRooms: null,
        isLoading: false,
        items: []
    }),

    created() {
        this.selectedRooms = this.value;
    }
};
</script>