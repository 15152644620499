var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Modification d'un article ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationObserver',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Nom ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.label),callback:function ($$v) {_vm.$set(_vm.updatedItem, "label", $$v)},expression:"updatedItem.label"}})]}}])})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Référence ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Référence","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.reference),callback:function ($$v) {_vm.$set(_vm.updatedItem, "reference", $$v)},expression:"updatedItem.reference"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Prix ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Prix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.baseprice),callback:function ($$v) {_vm.$set(_vm.updatedItem, "baseprice", $$v)},expression:"updatedItem.baseprice"}})]}}])})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Unité ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Unité","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.unit),callback:function ($$v) {_vm.$set(_vm.updatedItem, "unit", $$v)},expression:"updatedItem.unit"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" TVA ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"TVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.vat),callback:function ($$v) {_vm.$set(_vm.updatedItem, "vat", $$v)},expression:"updatedItem.vat"}})]}}])})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Couleur ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Couleurs","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.colors),callback:function ($$v) {_vm.$set(_vm.updatedItem, "colors", $$v)},expression:"updatedItem.colors"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Categorie ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Categorie","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.category),callback:function ($$v) {_vm.$set(_vm.updatedItem, "category", $$v)},expression:"updatedItem.category"}})]}}])})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Dimensions ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Dimensions","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.dimensions),callback:function ($$v) {_vm.$set(_vm.updatedItem, "dimensions", $$v)},expression:"updatedItem.dimensions"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Marque ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Marque","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.brand),callback:function ($$v) {_vm.$set(_vm.updatedItem, "brand", $$v)},expression:"updatedItem.brand"}})]}}])})],1)],1)],1),_c('v-col')],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('span',{staticClass:"white-space-no-wrap"},[_vm._v(" Description ")]),_c('ValidationProvider',{attrs:{"name":"Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-textarea',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.updatedItem.description),callback:function ($$v) {_vm.$set(_vm.updatedItem, "description", $$v)},expression:"updatedItem.description"}})]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.getSOTItemAssetImages(_vm.updatedItem.assets)),function(asset){return _c('v-card',{key:asset.id,staticClass:"asset-card d-flex flex-column py-2 mr-2 mb-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2 pa-0 mx-2"},[_vm._v(" "+_vm._s(asset.label)+" ")]),_c('v-divider',{staticClass:"mb-2"}),_c('a',{staticClass:"d-flex flex-grow-1 overflow-auto justify-center no-text-decoration",attrs:{"href":_vm.getSOTItemAssetUrl(asset),"target":"_blank"}},[_c('img',{attrs:{"src":_vm.getSOTItemAssetUrl(asset),"height":"100%"}})]),_c('v-divider',{staticClass:"mt-2"}),_c('v-card-actions',{staticClass:"px-0 pb-0"},[_c('v-row',{staticClass:"fill-height mx-2",attrs:{"align":"end","no-gutters":""}},[_c('v-col',[_c('SOTUploadItemAssetDialog',{attrs:{"item":_vm.item,"asset":asset},on:{"updated":function($event){return _vm.refreshAssets()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Editer cette image ")])]}}],null,true)})],1),_c('v-col',{staticClass:"text-right"},[_c('SOTDeleteItemAssetDialog',{attrs:{"asset":asset},on:{"deleted":function($event){return _vm.refreshAssets()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash ")])],1)]}}],null,true)},[_vm._v(" Supprimer cette image ")])]}}],null,true)})],1)],1)],1)],1)}),_vm._l((_vm.getSOTItemAssetDocuments(_vm.updatedItem.assets)),function(asset){return _c('v-card',{key:asset.id,staticClass:"asset-card d-flex flex-column fill-height py-2 mr-2 mb-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"subtitle-2 pa-0 mx-2"},[_vm._v(" "+_vm._s(asset.label)+" ")]),_c('v-divider',{staticClass:"mb-2"}),_c('a',{staticClass:"d-flex flex-grow-1 justify-center no-text-decoration",attrs:{"href":_vm.getSOTItemAssetUrl(asset),"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" fas fa-file ")])],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-card-actions',{staticClass:"px-0 pb-0"},[_c('v-row',{staticClass:"fill-height mx-2",attrs:{"align":"end","no-gutters":""}},[_c('v-col',[_c('SOTUploadItemAssetDialog',{attrs:{"item":_vm.item,"asset":asset},on:{"updated":function($event){return _vm.refreshAssets()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Editer ce document ")])]}}],null,true)})],1),_c('v-col',{staticClass:"text-right"},[_c('SOTDeleteItemAssetDialog',{attrs:{"asset":asset},on:{"deleted":function($event){return _vm.refreshAssets()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash ")])],1)]}}],null,true)},[_vm._v(" Supprimer ce document ")])]}}],null,true)})],1)],1)],1)],1)}),_vm._l((_vm.getSOTItemAssetYoutubeLinks(_vm.updatedItem.assets)),function(asset){return _c('v-card',{key:asset.id,staticClass:"asset-card d-flex flex-column fill-height py-2 mr-2 mb-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"subtitle-2 pa-0 mx-2"},[_vm._v(" "+_vm._s(asset.label)+" ")]),_c('v-divider',{staticClass:"mb-2"}),_c('a',{staticClass:"d-flex flex-grow-1 justify-center no-text-decoration",attrs:{"href":asset.url,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" fab fa-youtube ")])],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-card-actions',{staticClass:"px-0 pb-0"},[_c('v-row',{staticClass:"fill-height mx-2",attrs:{"align":"end","no-gutters":""}},[_c('v-col',[_c('SOTUploadItemAssetDialog',{attrs:{"item":_vm.item,"asset":asset},on:{"updated":function($event){return _vm.refreshAssets()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Editer cette vidéo youtube ")])]}}],null,true)})],1),_c('v-col',{staticClass:"text-right"},[_c('SOTDeleteItemAssetDialog',{attrs:{"asset":asset},on:{"deleted":function($event){return _vm.refreshAssets()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash ")])],1)]}}],null,true)},[_vm._v(" Supprimer cette vidéo youtube ")])]}}],null,true)})],1)],1)],1)],1)}),_vm._l((_vm.getSOTItemAssetVideoLinks(_vm.updatedItem.assets)),function(asset){return _c('v-card',{key:asset.id,staticClass:"asset-card d-flex flex-column fill-height py-2 mr-2 mb-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"subtitle-2 pa-0 mx-2"},[_vm._v(" "+_vm._s(asset.label)+" ")]),_c('v-divider',{staticClass:"mb-2"}),_c('a',{staticClass:"d-flex flex-grow-1 justify-center no-text-decoration",attrs:{"href":asset.url,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" fas fa-link ")])],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-card-actions',{staticClass:"px-0 pb-0"},[_c('v-row',{staticClass:"fill-height mx-2",attrs:{"align":"end","no-gutters":""}},[_c('v-col',[_c('SOTUploadItemAssetDialog',{attrs:{"item":_vm.item,"asset":asset},on:{"updated":function($event){return _vm.refreshAssets()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Editer ce lien vidéo ")])]}}],null,true)})],1),_c('v-col',{staticClass:"text-right"},[_c('SOTDeleteItemAssetDialog',{attrs:{"asset":asset},on:{"deleted":function($event){return _vm.refreshAssets()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","icon":"","x-small":""},on:{"click":function($event){$event.preventDefault();}}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash ")])],1)]}}],null,true)},[_vm._v(" Supprimer ce lien vidéo ")])]}}],null,true)})],1)],1)],1)],1)}),_c('SOTUploadItemAssetDialog',{attrs:{"item":_vm.item},on:{"uploaded":function($event){return _vm.refreshAssets()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-card',_vm._g({staticClass:"asset-card d-flex align-center justify-center",attrs:{"outlined":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" fas fa-plus ")])],1)]}}],null,true)},[_vm._v(" Ajouter une image, un document ou une vidéo ")])]}}])})],2)])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Modifier ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }