<template>
    <v-dialog v-model="isOpen" width="80%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Modification d'un article
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <ValidationObserver ref="observer">
                            <v-row>
                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Nom </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Nom" rules="required">
                                                <v-text-field v-model="updatedItem.label" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Référence </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Référence" rules="">
                                                <v-text-field v-model="updatedItem.reference" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Prix </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Prix" rules="required">
                                                <v-text-field v-model="updatedItem.baseprice" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Unité </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Unité" rules="">
                                                <v-text-field v-model="updatedItem.unit" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> TVA </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="TVA" rules="required">
                                                <v-text-field v-model="updatedItem.vat" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Couleur </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Couleurs" rules="">
                                                <v-text-field v-model="updatedItem.colors" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>

                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Categorie </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Categorie" rules="">
                                                <v-text-field v-model="updatedItem.category" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Dimensions </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Dimensions" rules="">
                                                <v-text-field v-model="updatedItem.dimensions" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Marque </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Marque" rules="">
                                                <v-text-field v-model="updatedItem.brand" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>

                                </v-col>
                            </v-row>

                            <v-row align="center">
                                <v-col>
                                    <span class="white-space-no-wrap"> Description </span>

                                    <ValidationProvider v-slot="{ errors, failed }" name="Description" rules="">
                                        <v-textarea v-model="updatedItem.description" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </ValidationObserver>
                    </v-col>

                    <v-col cols="6">
                        <div class="d-flex flex-wrap">
                            <v-card v-for="asset of getSOTItemAssetImages(updatedItem.assets)" :key="asset.id" class="asset-card d-flex flex-column py-2 mr-2 mb-2" outlined>
                                <v-card-title class="text-subtitle-2 pa-0 mx-2">
                                    {{ asset.label }}
                                </v-card-title>

                                <v-divider class="mb-2" />

                                <a class="d-flex flex-grow-1 overflow-auto justify-center no-text-decoration" :href="getSOTItemAssetUrl(asset)" target="_blank">
                                    <img :src="getSOTItemAssetUrl(asset)" height="100%">
                                </a>

                                <v-divider class="mt-2" />

                                <v-card-actions class="px-0 pb-0">
                                    <v-row class="fill-height mx-2" align="end" no-gutters>
                                        <v-col>
                                            <SOTUploadItemAssetDialog :item="item" :asset="asset" @updated="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-edit </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Editer cette image
                                                    </v-tooltip>
                                                </template>
                                            </SOTUploadItemAssetDialog>
                                        </v-col>

                                        <v-col class="text-right">
                                            <SOTDeleteItemAssetDialog :asset="asset" @deleted="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-trash </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer cette image
                                                    </v-tooltip>
                                                </template>
                                            </SOTDeleteItemAssetDialog>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>

                            <v-card v-for="asset of getSOTItemAssetDocuments(updatedItem.assets)" :key="asset.id" class="asset-card d-flex flex-column fill-height py-2 mr-2 mb-2" outlined>
                                <v-card-title class="subtitle-2 pa-0 mx-2">
                                    {{ asset.label }}
                                </v-card-title>

                                <v-divider class="mb-2" />

                                <a class="d-flex flex-grow-1 justify-center no-text-decoration" :href="getSOTItemAssetUrl(asset)" target="_blank">
                                    <v-icon color="primary"> fas fa-file </v-icon>
                                </a>

                                <v-divider class="mt-2" />

                                <v-card-actions class="px-0 pb-0">
                                    <v-row class="fill-height mx-2" align="end" no-gutters>
                                        <v-col>
                                            <SOTUploadItemAssetDialog :item="item" :asset="asset" @updated="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-edit </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Editer ce document
                                                    </v-tooltip>
                                                </template>
                                            </SOTUploadItemAssetDialog>
                                        </v-col>

                                        <v-col class="text-right">
                                            <SOTDeleteItemAssetDialog :asset="asset" @deleted="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-trash </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer ce document
                                                    </v-tooltip>
                                                </template>
                                            </SOTDeleteItemAssetDialog>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>

                            <v-card v-for="asset of getSOTItemAssetYoutubeLinks(updatedItem.assets)" :key="asset.id" class="asset-card d-flex flex-column fill-height py-2 mr-2 mb-2" outlined>
                                <v-card-title class="subtitle-2 pa-0 mx-2">
                                    {{ asset.label }}
                                </v-card-title>

                                <v-divider class="mb-2" />

                                <a class="d-flex flex-grow-1 justify-center no-text-decoration" :href="asset.url" target="_blank">
                                    <v-icon color="primary"> fab fa-youtube </v-icon>
                                </a>

                                <v-divider class="mt-2" />

                                <v-card-actions class="px-0 pb-0">
                                    <v-row class="fill-height mx-2" align="end" no-gutters>
                                        <v-col>
                                            <SOTUploadItemAssetDialog :item="item" :asset="asset" @updated="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-edit </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Editer cette vidéo youtube
                                                    </v-tooltip>
                                                </template>
                                            </SOTUploadItemAssetDialog>
                                        </v-col>

                                        <v-col class="text-right">
                                            <SOTDeleteItemAssetDialog :asset="asset" @deleted="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-trash </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer cette vidéo youtube
                                                    </v-tooltip>
                                                </template>
                                            </SOTDeleteItemAssetDialog>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>

                            <v-card v-for="asset of getSOTItemAssetVideoLinks(updatedItem.assets)" :key="asset.id" class="asset-card d-flex flex-column fill-height py-2 mr-2 mb-2" outlined>
                                <v-card-title class="subtitle-2 pa-0 mx-2">
                                    {{ asset.label }}
                                </v-card-title>

                                <v-divider class="mb-2" />

                                <a class="d-flex flex-grow-1 justify-center no-text-decoration" :href="asset.url" target="_blank">
                                    <v-icon color="primary"> fas fa-link </v-icon>
                                </a>

                                <v-divider class="mt-2" />

                                <v-card-actions class="px-0 pb-0">
                                    <v-row class="fill-height mx-2" align="end" no-gutters>
                                        <v-col>
                                            <SOTUploadItemAssetDialog :item="item" :asset="asset" @updated="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-edit </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Editer ce lien vidéo
                                                    </v-tooltip>
                                                </template>
                                            </SOTUploadItemAssetDialog>
                                        </v-col>

                                        <v-col class="text-right">
                                            <SOTDeleteItemAssetDialog :asset="asset" @deleted="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-trash </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer ce lien vidéo
                                                    </v-tooltip>
                                                </template>
                                            </SOTDeleteItemAssetDialog>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>

                            <SOTUploadItemAssetDialog :item="item" @uploaded="refreshAssets()">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-card class="asset-card d-flex align-center justify-center" outlined v-on="{ ...dialog, ...tooltip }">
                                                <v-icon color="primary"> fas fa-plus </v-icon>
                                            </v-card>
                                        </template>
                                        Ajouter une image, un document ou une vidéo
                                    </v-tooltip>
                                </template>
                            </SOTUploadItemAssetDialog>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Modifier </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import sotMixin from '../../../mixins/sot.js';
import SOTUploadItemAssetDialog from './SOTUploadItemAssetDialog.vue';
import SOTDeleteItemAssetDialog from './SOTDeleteItemAssetDialog.vue';

export default {
    name: 'SOTUpdateItemDialogt',

    props: {
        item: { type: Object, required: true }
    },

    mixins: [sotMixin],

    components: {
        SOTUploadItemAssetDialog,
        SOTDeleteItemAssetDialog
    },

    data: () => ({
        isOpen: false,
        updatedItem: {}
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.assetsUpdated = false;
                if (this.item) {
                    this.updatedItem = JSON.parse(JSON.stringify(this.item));
                } else if (this.itemId) {
                    this.fetchItem(this.itemId);
                }
            } else {
                if (this.assetsUpdated) {
                    this.$emit('updated');
                }
            }
        }
    },

    methods: {
        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = { item: this.updatedItem };

                const { success, err } = await this.repos.sot.updateSOTItem(this.item.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la mise à jour de l\'article',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchItem(itemId) {
            try {
                this.setLoading(true);

                const query = { include: 'assets' };
                const { item, err } = await this.repos.sot.getSOTItem(itemId, query);
                if (err) {
                    throw new Error(err);
                }
                this.updatedItem = JSON.parse(JSON.stringify(item));
                return item;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de l\'article',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async refreshAssets() {
            this.assetsUpdated = true;
            const item = await this.fetchItem(this.updatedItem.id);
            if (item) {
                this.updatedItem.assets = item.assets;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.asset-card {
    width: 160px;
    height: 160px;
}
</style>