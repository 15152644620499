var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Création d'un article ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-text',[_c('ValidationObserver',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Nom ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.label),callback:function ($$v) {_vm.$set(_vm.item, "label", $$v)},expression:"item.label"}})]}}])})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Référence ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Référence","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.reference),callback:function ($$v) {_vm.$set(_vm.item, "reference", $$v)},expression:"item.reference"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Prix ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Prix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.baseprice),callback:function ($$v) {_vm.$set(_vm.item, "baseprice", $$v)},expression:"item.baseprice"}})]}}])})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Unité ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Unité","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.unit),callback:function ($$v) {_vm.$set(_vm.item, "unit", $$v)},expression:"item.unit"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" TVA ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"TVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.vat),callback:function ($$v) {_vm.$set(_vm.item, "vat", $$v)},expression:"item.vat"}})]}}])})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Couleur ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Couleurs","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.colors),callback:function ($$v) {_vm.$set(_vm.item, "colors", $$v)},expression:"item.colors"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Categorie ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Categorie","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.category),callback:function ($$v) {_vm.$set(_vm.item, "category", $$v)},expression:"item.category"}})]}}])})],1)],1)],1),_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Dimensions ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Dimensions","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.dimensions),callback:function ($$v) {_vm.$set(_vm.item, "dimensions", $$v)},expression:"item.dimensions"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Marque ")]),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Marque","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.brand),callback:function ($$v) {_vm.$set(_vm.item, "brand", $$v)},expression:"item.brand"}})]}}])})],1)],1)],1),_c('v-col')],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Description "),_c('ValidationProvider',{attrs:{"name":"Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('v-textarea',{attrs:{"error-messages":errors,"hide-details":!failed,"outlined":"","dense":""},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})]}}])})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Enregistrer ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }