<template>
    <div class="ma-4">
        Définissez ici des modèles de pièces types avec leur différentes possibilités de personnalisation, puisées parmi des ensembles de choix possibles prédéfinis pour une personnalisation. <br>
        Ces modèles de pièces sont ensuite assemblées dans des typologies standard de lots.

        <div class="d-flex my-2">
            <v-text-field class="flex-grow-0 mr-2" label="Recherche" outlined hide-details dense>
                <template v-slot:prepend-inner>
                    <v-icon left> fas fa-search </v-icon>
                </template>
            </v-text-field>

            <v-select class="flex-grow-0 mr-2" label="Pièces" outlined hide-details dense />

            <v-spacer />

            <SOTProductLineRoomModelAddDialog>
                <template v-slot:activator="{ on }">
                    <v-btn class="mr-2" color="app-blue" depressed small dark v-on="on">
                        <v-icon x-small left> fas fa-plus </v-icon>
                        Ajouter
                    </v-btn>
                </template>
            </SOTProductLineRoomModelAddDialog>
        </div>

        <v-row>
            <v-col cols="2" v-for="(model, i) of models" :key="i">
                <v-hover v-slot="{ hover }">
                    <v-card class="d-flex flex-column fill-height">
                        <v-card-title class="text-h6 py-2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span class="card-title" v-on="on">
                                        {{ model.label }}
                                    </span>
                                </template>
                                {{ model.label }}
                            </v-tooltip>
                        </v-card-title>

                        <v-divider />

                        <v-card-text class="flex-grow-1 pa-0">
                            <v-list dense>
                                <v-list-item v-for="(item, i) of model.items" :key="i" style="min-height: 20px">
                                    <v-list-item-content class="py-1">
                                        <v-list-item-title>
                                            {{ item.label }}
                                        </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-icon v-if="item.checked" color="red" small> fas fa-star </v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card-text>

                        <v-card-actions class="d-flex flex-column align-baseline">
                            <div>
                                <v-chip v-for="type of model.types" class="mr-2" :key="type" label small>
                                    {{ type }}
                                </v-chip>
                            </div>

                            <div class="align-self-end" style="min-height: 28px">
                                <v-sheet v-show="hover" class="rounded" color="white">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mr-1" color="accent" small icon v-on="{...tooltip }">
                                                <v-icon small> fas fa-clone </v-icon>
                                            </v-btn>
                                        </template>
                                        Dupliquer
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mr-1" color="app-blue" small icon v-on="{...tooltip }">
                                                <v-icon small> fas fa-edit </v-icon>
                                            </v-btn>
                                        </template>
                                        Modifier
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mr-1" color="red" small icon v-on="{...tooltip }">
                                                <v-icon small> fas fa-trash-alt </v-icon>
                                            </v-btn>
                                        </template>
                                        Supprimer
                                    </v-tooltip>
                                </v-sheet>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import SOTProductLineRoomModelAddDialog from './SOTProductLineRoomModelAddDialog.vue';

export default {
    name: 'SOTProductLineRoomModels',

    props: {
        productLine: { type: Object, required: true }
    },

    components: {
        SOTProductLineRoomModelAddDialog
    },

    data: () => ({
        models: [
            {
                label: 'Chambre simple',
                items: [
                    { label: 'Radiateurs', checked: false },
                    { label: 'Peinture mur', checked: false },
                    { label: 'Sois chambre', checked: false },
                    { label: 'Placards', checked: true }
                ],
                types: ['Chambre']
            },
            {
                label: 'Salle de bain 1',
                items: [
                    { label: 'Radiateurs humides', checked: false },
                    { label: 'Carrelage eau', checked: true },
                    { label: 'Baignoire', checked: false },
                    { label: 'Bac de douche', checked: false }
                ],
                types: ['Salle de bain', 'Salle d\'eau']
            },
            {
                label: 'Salle de bain 2',
                items: [
                    { label: 'Radiateurs humides', checked: false },
                    { label: 'Carrelage eau', checked: false },
                    { label: 'Baignoire', checked: false },
                    { label: 'Bac de douche', checked: false },
                    { label: 'XC', checked: false }
                ],
                types: ['Salle de bain', 'Salle d\'eau']
            },
            {
                label: 'Cuisine fermée',
                items: [
                    { label: 'Radiateurs humides', checked: false },
                    { label: 'Carrelage eau', checked: false },
                    { label: 'Plan de travail', checked: false },
                    { label: 'Evier', checked: false },
                    { label: 'Porte', checked: false }
                ],
                types: ['Cuisine']
            },
            {
                label: 'Cuisine ouverte',
                items: [
                    { label: 'Radiateurs humides', checked: false },
                    { label: 'Carrelage eau', checked: false },
                    { label: 'Plan de travail', checked: false },
                    { label: 'Evier', checked: false },
                    { label: 'Bar', checked: false }
                ],
                types: ['Cuisine']
            }
        ]
    })
};
</script>