<template>
    <v-dialog v-model="isOpen" width="500">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    <template v-if="isEdition">
                        Modification d'une image, d'un document ou d'une vidéo
                    </template>
                    <template v-else>
                        Ajouter une image, un document ou une vidéo
                    </template>
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <v-text-field v-model="label" label="Titre" outlined dense />

                <template v-if="!isEdition">
                    <v-select v-model="type" :items="types" label="Type" :menu-props="{ bottom: true, offsetY: true }" outlined dense />

                    <v-file-input v-model="file" v-show="type === 'file'" label="Fichier (image ou document)" clear-icon="far fa-times-circle" append-icon="fas fa-paperclip" prepend-icon="" chips small-chips show-size outlined dense />

                    <v-text-field v-model="link" v-show="type === 'link'" label="Url vidéo" outlined dense />
                </template>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small>
                    <template v-if="isEdition">
                        Modifier
                    </template>
                    <template v-else>
                        Ajouter
                    </template>
                </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'SOTUploadPlanAssetDialog',

    props: {
        plan: { type: Object, required: true },
        asset: { type: Object, default: null }
    },

    data: () => ({
        isOpen: false,

        label: '',
        type: null,
        file: null,
        link: null,

        types: [
            { value: 'file', text: 'Fichier' },
            { value: 'link', text: 'Url vidéo' }
        ]
    }),

    methods: {
        open() {
            this.isOpen = true;
        },

        async submit() {
            if (this.isEdition) {
                await this.updateAsset();
            } else {
                await this.createAsset();
            }
        },

        async createAsset() {
            try {
                this.setLoading(true);

                const body = {
                    label: this.label,
                    type: this.type,
                    link: this.link,
                    file: this.file
                };

                const { success, err } = await this.repos.sot.uploadSOTPlanAlternativeAsset(this.plan.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('uploaded');
                    this.$notify({
                        title: 'Information',
                        text: 'Le document a bien été téléchargé',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du téléchargement du document',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async updateAsset() {
            try {
                this.setLoading(true);

                const body = {
                    label: this.label
                };

                const { success, err } = await this.repos.sot.updateSOTPlanAlternativeAsset(this.asset.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated');
                    this.$notify({
                        title: 'Information',
                        text: 'Le document a bien été modifié',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue de la mise à jour du document',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    computed: {
        isEdition() {
            return this.asset !== null;
        }
    },

    watch: {
        isOpen() {
            this.label = '';
            this.file = null;
            if (this.asset) {
                this.label = this.asset.label;
            }
        }
    }
};
</script>
