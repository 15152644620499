<template>
    <div>
        <v-row>
            <v-col>
                <p>
                    Définissez ici la liste exhaustive des articles et prestations proposées à la personnalisation aux acquéreurs; ces articles sont ensuite associés en groupe d'options parmi lesquelles les acquéreurs devront réaliser leurs choix.
                </p>
            </v-col>
        </v-row>

        <v-card class="d-flex align-center mb-4 pa-2">
            <v-text-field v-model="quickSearch" class="flex-grow-0 mr-2" label="Recherche" color="app-blue" prepend-inner-icon="fas fa-search" outlined hide-details dense />

            <v-select v-model="brandsSearch" :items="brands" class="flex-grow-0 mr-2" label="Marques" color="app-blue" :menu-props="{ bottom: true, offsetY: true }" hide-details multiple clearable outlined dense />
            <v-select v-model="categoriesSearch" :items="categories" class="flex-grow-0" label="Catégories" color="app-blue" :menu-props="{ bottom: true, offsetY: true }" hide-details multiple clearable outlined dense />

            <v-spacer />

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-chip class="mr-2" label small v-on="on"> {{ itemsCount }} </v-chip>
                </template>
                Nombre total d'articles
            </v-tooltip>
            
            <SOTAddItemDialog @created="fetchItems()">
                <template v-slot:activator="{ on }">
                    <v-btn class="mr-2" color="app-blue" depressed small dark v-on="on">
                        <v-icon x-small left> fas fa-plus </v-icon>
                        Ajouter
                    </v-btn>
                </template>
            </SOTAddItemDialog>

            <v-pagination v-model="page" :length="itemsTotalPages" color="app-blue" />
        </v-card>

        <v-row class="pb-1">
            <v-col cols="2" md="3" xl="2" v-for="(item, i) of items" :key="i">
                <SOTItem :item="item" @reload="fetchItems()" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import SOTItem from './SOTItem.vue';
import SOTAddItemDialog from './SOTAddItemDialog.vue';

export default {
    name: 'SOTItems',

    components: {
        SOTItem,
        SOTAddItemDialog
    },

    data: () => ({
        items: [],
        itemsCount: 0,
        itemsTotalPages: 1,
        brands: [],
        categories: [],

        page: 1,
        limit: 20,
        quickSearch: null,
        brandsSearch: null,
        categoriesSearch: null,
        searchTimeout: null
    }),

    watch: {
        quickSearch() {
            this.doSearch();
        },
        page() {
            this.fetchItems();
        },
        brandsSearch() {
            this.fetchItems();
        },
        categoriesSearch() {
            this.fetchItems();
        }
    },

    methods: {
        doSearch() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.fetchItems();
            }, 500);
        },

        async fetchItems() {
            try {
                this.setLoading(true);

                const { items, brands, categories, count, totalPages, err } = await this.repos.sot.getSOTItems(this.query);
                if (err) {
                    throw new Error(err);
                }
                this.items = items;
                this.itemsCount = count;
                this.itemsTotalPages = totalPages;
                this.brands = brands;
                this.categories = categories;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement du catalogue tma',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    computed: {
        query() {
            const orderBy = 'label:asc';
            const query = {
                page: this.page,
                limit: this.limit,
                orderBy,
                include: 'assets'
            };

            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }

            if (Array.isArray(this.brandsSearch) && this.brandsSearch.length > 0) {
                query.brands = this.brandsSearch.join(',');
            }

            if (Array.isArray(this.categoriesSearch) && this.categoriesSearch.length > 0) {
                query.categories = this.categoriesSearch.join(',');
            }

            return query;
        }
    },

    created() {
        this.fetchItems();
    }
};
</script>