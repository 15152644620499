<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Nouvelle modèle de pièce
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <p>
                    Définissez ici un nouveau modèle de pièce prédéfini avec ses différentes possibilités de personnalisation, puisées parmi des ensembles de choix possibles prédéfinis dans l'onglet Prestations.
                </p>

                <ValidationObserver ref="observer">
                    <v-row align="center">
                        <v-col cols="2">
                            Libellé

                            <span class="red--text"> * </span>
                        </v-col>

                        <v-col>
                            <ValidationProvider v-slot="{ errors, failed }" name="Programmes" rules="required">
                                <v-text-field v-model="productLine.label" :error-messages="errors" :hide-details="!failed" outlined dense />
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="2">
                            Prestation
                        </v-col>

                        <v-col class="d-flex align-center">
                            <v-select v-model="productLine.category" :items="['Carrelage eau']" class="flex-grow-0 mr-2" hint="Quelles sont les prestations à personnaliser pour ce modèle de pièce?" :menu-props="{ bottom: true, offsetY: true }" persistent-hint outlined dense />

                            <v-btn class="flex-grow-0 mr-2 align-self-start" color="primary" small> Ajouter </v-btn>

                            <v-checkbox class="flex-grow-0 align-self-start mt-0" label="le choix est obligatoire"></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip class="mr-2" label small v-on="on">
                                        <v-icon x-small left> fas fa-star </v-icon>
                                        <b>
                                            Radiateur humide
                                        </b>
                                        <v-icon x-small right> fas fa-times-circle </v-icon>
                                    </v-chip>
                                </template>
                                3 choix possibles - 0 à 115 €
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="2">
                            Type de pièce
                        </v-col>

                        <v-col class="d-flex align-center">
                            <v-select v-model="productLine.category" :items="['WC']" class="flex-grow-0 mr-2" hint="A quel(s) type(s) de pièce classqiue ce modèle de pièce prédéfini peut-il correspondre?" :menu-props="{ bottom: true, offsetY: true }" persistent-hint outlined dense />

                            <v-btn class="flex-grow-0 mr-2 align-self-start" color="primary" small> Ajouter </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-chip class="mr-2" label small>
                                Cuisine
                                <v-icon x-small right> fas fa-times-circle </v-icon>
                            </v-chip>

                            <v-chip class="mr-2" label small>
                                Salle de bain
                                <v-icon x-small right> fas fa-times-circle </v-icon>
                            </v-chip>

                            <v-chip class="mr-2" label small>
                                Salle d'eau
                                <v-icon x-small right> fas fa-times-circle </v-icon>
                            </v-chip>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            Indications acquéreurs
                        </v-col>

                        <v-col>
                            <v-textarea v-model="productLine.description" rows="2" hint="Quelles sont les informations complémentaires éventuelles à indiquer aux acquéreurs à propos de ce modèle de pièce?" persistent-hint outlined dense />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            Description
                        </v-col>

                        <v-col>
                            <v-textarea v-model="productLine.description" rows="2" hint="Quelles sont les précisions complémentaires éventuelles à partager en interne à propos de ce modèle de pièce?" persistent-hint outlined dense />
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'SOTProductLineRoomModelAddDialog',

    data: () => ({
        isOpen: false,
        productLine: {}
    }),

    methods: {
        async submit() {

        }
    }
};
</script>