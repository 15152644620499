var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4"},[_vm._v(" Définissez ici des modèles de pièces types avec leur différentes possibilités de personnalisation, puisées parmi des ensembles de choix possibles prédéfinis pour une personnalisation. "),_c('br'),_vm._v(" Ces modèles de pièces sont ensuite assemblées dans des typologies standard de lots. "),_c('div',{staticClass:"d-flex my-2"},[_c('v-text-field',{staticClass:"flex-grow-0 mr-2",attrs:{"label":"Recherche","outlined":"","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-search ")])]},proxy:true}])}),_c('v-select',{staticClass:"flex-grow-0 mr-2",attrs:{"label":"Pièces","outlined":"","hide-details":"","dense":""}}),_c('v-spacer'),_c('SOTProductLineRoomModelAddDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"app-blue","depressed":"","small":"","dark":""}},on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" Ajouter ")],1)]}}])})],1),_c('v-row',_vm._l((_vm.models),function(model,i){return _c('v-col',{key:i,attrs:{"cols":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex flex-column fill-height"},[_c('v-card-title',{staticClass:"text-h6 py-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"card-title"},on),[_vm._v(" "+_vm._s(model.label)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(model.label)+" ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"flex-grow-1 pa-0"},[_c('v-list',{attrs:{"dense":""}},_vm._l((model.items),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"min-height":"20px"}},[_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.label)+" ")])],1),_c('v-list-item-action',[(item.checked)?_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v(" fas fa-star ")]):_vm._e()],1)],1)}),1)],1),_c('v-card-actions',{staticClass:"d-flex flex-column align-baseline"},[_c('div',_vm._l((model.types),function(type){return _c('v-chip',{key:type,staticClass:"mr-2",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(type)+" ")])}),1),_c('div',{staticClass:"align-self-end",staticStyle:{"min-height":"28px"}},[_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"rounded",attrs:{"color":"white"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"accent","small":"","icon":""}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-clone ")])],1)]}}],null,true)},[_vm._v(" Dupliquer ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"app-blue","small":"","icon":""}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Modifier ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"red","small":"","icon":""}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash-alt ")])],1)]}}],null,true)},[_vm._v(" Supprimer ")])],1)],1)])],1)]}}],null,true)})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }