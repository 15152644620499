<template>
    <v-autocomplete v-model="selectedVats" :items="vats" :disabled="disabled" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedVats)" :multiple="multiple" :label="label" background-color="white" hide-details no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip color="accent" label small dark close @click:close="selectedVats.splice(data.index, 1)">
                {{ data.item }} %
            </v-chip>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content >
                <v-list-item-title >
                    <v-chip color="accent" label small dark>
                        {{ item }} %
                    </v-chip>
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>

export default {
    name: 'VATSelect',

    props: {
        value: { required: true },
        label: { type: String, default: 'TVA' },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false }
    },

    data: () => ({
        selectedVats: null,
        vats: [5.5, 10, 20]
    }),

    created() {
        this.selectedVats = this.value;
    }
};
</script>