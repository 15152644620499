<template>
    <v-container>
        <v-tabs class="mb-2 rounded elevation-2" color="app-blue" fixed-tabs v-model="tab">
            <v-tab> Ventes </v-tab>
            <v-tab> Alternatives TMA </v-tab>
            <v-tab> Catalogue des articles </v-tab>
            <v-tab> Gammes de prestation </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" style="background: transparent">
            <v-tab-item>
                <Sales displaySOT />

            </v-tab-item>

            <v-tab-item>
                <SOTPlanAlternatives />
            </v-tab-item>

            <v-tab-item>
                <SOTItems />
            </v-tab-item>

            <v-tab-item>
                <SOTProductLines />
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import Sales from './admin/Sales.vue';
import SOTItems from '../components/sot/items/SOTItems.vue';
import SOTProductLines from '../components/sot/productlines/SOTProductLines.vue';
import SOTPlanAlternatives from '../components/sot/planalternatives/SOTPlanAlternatives.vue';

export default {
    name: 'SOTAdmin',

    components: {
        Sales,
        SOTItems,
        SOTProductLines,
        SOTPlanAlternatives
    },

    data: () => ({
        tab: 0
    }),

    created() {
        this.$store.dispatch('products/fetchProductsSummary');
    }
};
</script>
