<template>
    <v-dialog v-model="isOpen" width="80%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Modifier une alternative TMA
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <ValidationObserver ref="observer">
                            <v-row>
                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Libellé </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Libellé" rules="required">
                                                <v-text-field v-model="updatedPlan.label" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Montant TTC </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="Montant TTC" rules="required">
                                                <v-text-field v-model="updatedPlan.baseprice" :error-messages="errors" :hide-details="!failed" outlined dense />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Programmes </span>
                                        </v-col>

                                        <v-col>
                                            <ProgramAutocomplete v-model="updatedPlan.programs" multiple />
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>

                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> TVA </span>
                                        </v-col>

                                        <v-col>
                                            <ValidationProvider v-slot="{ errors, failed }" name="TVA" rules="">
                                                <VATSelect v-model="updatedPlan.vat" label="" :error-messages="errors" :hide-details="!failed" />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <span class="white-space-no-wrap"> Typologies </span>
                                        </v-col>

                                        <v-col>
                                            <ProductRoomsSelect v-model="updatedPlan.rooms_list" multiple />
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            Travaux
                                        </v-col>

                                        <v-col>
                                            <div class="d-flex align-center">
                                                <v-text-field v-model="newItem" class="flex-grow-0 mr-2" @keyup.enter="addItem()" hide-details outlined dense />

                                                <v-btn @click="addItem()" small> Ajouter </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-chip-group column>
                                        <v-chip v-for="item of updatedPlan.items" :key="item" label>
                                            {{ item }}
                                            <v-btn @click="removeItem(item)" x-small icon>
                                                <v-icon icon x-small> fas fa-times </v-icon>
                                            </v-btn>
                                        </v-chip>
                                    </v-chip-group>
                                </v-col>
                            </v-row>
                        </ValidationObserver>
                    </v-col>

                    <v-col cols="6">
                        <div class="d-flex flex-wrap">
                            <v-card v-for="asset of getSOTItemAssetImages(updatedPlan.assets)" :key="asset.id" class="asset-card d-flex flex-column py-2 mr-2 mb-2" outlined>
                                <v-card-title class="text-subtitle-2 pa-0 mx-2">
                                    {{ asset.label }}
                                </v-card-title>

                                <v-divider class="mb-2" />

                                <a class="d-flex flex-grow-1 overflow-auto justify-center no-text-decoration" :href="getSOTPlanAlternativeAssetUrl(asset)" target="_blank">
                                    <img :src="getSOTPlanAlternativeAssetUrl(asset)" height="100%">
                                </a>

                                <v-divider class="mt-2" />

                                <v-card-actions class="px-0 pb-0">
                                    <v-row class="fill-height mx-2" align="end" no-gutters>
                                        <v-col>
                                            <SOTUploadPlanAssetDialog :plan="plan" :asset="asset" @updated="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-edit </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Editer cette image
                                                    </v-tooltip>
                                                </template>
                                            </SOTUploadPlanAssetDialog>
                                        </v-col>

                                        <v-col class="text-right">
                                            <SOTDeletePlanAssetDialog :asset="asset" @deleted="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-trash </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer cette image
                                                    </v-tooltip>
                                                </template>
                                            </SOTDeletePlanAssetDialog>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>

                            <v-card v-for="asset of getSOTItemAssetDocuments(updatedPlan.assets)" :key="asset.id" class="asset-card d-flex flex-column fill-height py-2 mr-2 mb-2" outlined>
                                <v-card-title class="subtitle-2 pa-0 mx-2">
                                    {{ asset.label }}
                                </v-card-title>

                                <v-divider class="mb-2" />

                                <a class="d-flex flex-grow-1 justify-center no-text-decoration" :href="getSOTPlanAlternativeAssetUrl(asset)" target="_blank">
                                    <v-icon color="primary"> fas fa-file </v-icon>
                                </a>

                                <v-divider class="mt-2" />

                                <v-card-actions class="px-0 pb-0">
                                    <v-row class="fill-height mx-2" align="end" no-gutters>
                                        <v-col>
                                            <SOTUploadPlanAssetDialog :plan="plan" :asset="asset" @updated="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-edit </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Editer ce document
                                                    </v-tooltip>
                                                </template>
                                            </SOTUploadPlanAssetDialog>
                                        </v-col>

                                        <v-col class="text-right">
                                            <SOTDeletePlanAssetDialog :asset="asset" @deleted="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-trash </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer ce document
                                                    </v-tooltip>
                                                </template>
                                            </SOTDeletePlanAssetDialog>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>

                            <v-card v-for="asset of getSOTItemAssetYoutubeLinks(updatedPlan.assets)" :key="asset.id" class="asset-card d-flex flex-column fill-height py-2 mr-2 mb-2" outlined>
                                <v-card-title class="subtitle-2 pa-0 mx-2">
                                    {{ asset.label }}
                                </v-card-title>

                                <v-divider class="mb-2" />

                                <a class="d-flex flex-grow-1 justify-center no-text-decoration" :href="asset.url" target="_blank">
                                    <v-icon color="primary"> fab fa-youtube </v-icon>
                                </a>

                                <v-divider class="mt-2" />

                                <v-card-actions class="px-0 pb-0">
                                    <v-row class="fill-height mx-2" align="end" no-gutters>
                                        <v-col>
                                            <SOTUploadPlanAssetDialog :plan="plan" :asset="asset" @updated="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-edit </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Editer cette vidéo youtube
                                                    </v-tooltip>
                                                </template>
                                            </SOTUploadPlanAssetDialog>
                                        </v-col>

                                        <v-col class="text-right">
                                            <SOTDeletePlanAssetDialog :asset="asset" @deleted="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-trash </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer cette vidéo youtube
                                                    </v-tooltip>
                                                </template>
                                            </SOTDeletePlanAssetDialog>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>

                            <v-card v-for="asset of getSOTItemAssetVideoLinks(updatedPlan.assets)" :key="asset.id" class="asset-card d-flex flex-column fill-height py-2 mr-2 mb-2" outlined>
                                <v-card-title class="subtitle-2 pa-0 mx-2">
                                    {{ asset.label }}
                                </v-card-title>

                                <v-divider class="mb-2" />

                                <a class="d-flex flex-grow-1 justify-center no-text-decoration" :href="asset.url" target="_blank">
                                    <v-icon color="primary"> fas fa-link </v-icon>
                                </a>

                                <v-divider class="mt-2" />

                                <v-card-actions class="px-0 pb-0">
                                    <v-row class="fill-height mx-2" align="end" no-gutters>
                                        <v-col>
                                            <SOTUploadPlanAssetDialog :plan="plan" :asset="asset" @updated="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="primary" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-edit </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Editer ce lien vidéo
                                                    </v-tooltip>
                                                </template>
                                            </SOTUploadPlanAssetDialog>
                                        </v-col>

                                        <v-col class="text-right">
                                            <SOTDeletePlanAssetDialog :asset="asset" @deleted="refreshAssets()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn color="error" icon x-small v-on="{ ...tooltip, ...dialog }" @click.prevent="">
                                                                <v-icon small> fas fa-trash </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer ce lien vidéo
                                                    </v-tooltip>
                                                </template>
                                            </SOTDeletePlanAssetDialog>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>

                            <SOTUploadPlanAssetDialog :plan="plan" @uploaded="refreshAssets()">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-card class="asset-card d-flex align-center justify-center" outlined v-on="{ ...dialog, ...tooltip }">
                                                <v-icon color="primary"> fas fa-plus </v-icon>
                                            </v-card>
                                        </template>
                                        Ajouter une image, un document ou une vidéo
                                    </v-tooltip>
                                </template>
                            </SOTUploadPlanAssetDialog>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Modifier </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import sotMixin from '../../../mixins/sot.js';
import VATSelect from '../../widgets/VATSelect.vue';
import ProductRoomsSelect from '../../widgets/ProductRoomsSelect.vue';
import SOTUploadPlanAssetDialog from './SOTUploadPlanAssetDialog.vue';
import SOTDeletePlanAssetDialog from './SOTDeletePlanAssetDialog.vue';
import ProgramAutocomplete from '../../widgets/ProgramAutocomplete.vue';

export default {
    name: 'SOTUpdatePlanDialog',

    props: {
        plan: { type: Object, required: true }
    },

    mixins: [sotMixin],

    components: {
        VATSelect,
        ProductRoomsSelect,
        ProgramAutocomplete,
        SOTUploadPlanAssetDialog,
        SOTDeletePlanAssetDialog
    },

    data: () => ({
        isOpen: false,
        updatedPlan: {},
        newItem: ''
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.assetsUpdated = false;
                if (this.plan) {
                    this.updatedPlan = JSON.parse(JSON.stringify(this.plan));
                } else if (this.planId) {
                    this.fetchPlan(this.planId);
                }
            } else {
                if (this.assetsUpdated) {
                    this.$emit('updated');
                }
            }
        }
    },

    methods: {
        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = { plan: this.updatedPlan };

                const { success, err } = await this.repos.sot.updateSOTPlanAlternative(this.plan.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la mise à jour de l\'article',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchPlan(planId) {
            try {
                this.setLoading(true);

                const query = { include: 'assets' };
                const { plan, err } = await this.repos.sot.getSOTPlanAlternative(planId, query);
                if (err) {
                    throw new Error(err);
                }
                this.updatedPlan = JSON.parse(JSON.stringify(plan));
                return plan;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de l\'alternative TMA',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async refreshAssets() {
            this.assetsUpdated = true;
            const plan = await this.fetchPlan(this.updatedPlan.id);
            if (plan) {
                this.updatedPlan.assets = plan.assets;
            }
        },

        addItem() {
            if (!this.updatedPlan.items.includes(this.newItem)) {
                this.updatedPlan.items.push(this.newItem);
            }
            this.newItem = '';
        },

        removeItem(item) {
            this.updatedPlan.items = this.updatedPlan.items.filter((i) => i !== item);
        }
    }
};
</script>

<style lang="scss" scoped>
.asset-card {
    width: 160px;
    height: 160px;
}
</style>