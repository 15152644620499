<template>
    <v-hover v-slot="{ hover }">
        <v-card class="d-flex flex-column" width="285px">
            <v-card-title class="d-flex flex-nowrap text-h6 py-2">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <span class="text-overflow-ellipsis" v-on="on">
                            {{ plan.label }}
                        </span>
                    </template>
                    {{ plan.label }}
                </v-tooltip>

                <v-spacer />

                <v-chip color="app-red" class="ml-5 py-5" dark outlined>
                    <template v-if="plan.baseprice">
                        {{ plan.baseprice | toCurrencyString() }} TTC
                        <template v-if="plan.vat">
                            <br>
                            TVA {{ plan.vat }} %
                        </template>
                    </template>
                    <template v-else>
                        Inclus
                    </template>
                </v-chip>
            </v-card-title>

            <v-card-text class="flex-grow-1">
                <template v-for="item of plan.items">
                    <v-tooltip top :key="item">
                        <template v-slot:activator="{ on }">
                            <p class="text-overflow-ellipsis mb-0" v-on="on">
                                - {{ item }}
                            </p>
                        </template>
                        {{ item }}
                    </v-tooltip>
                </template>

                <v-divider v-if="plan.rooms_list.length > 0" class="mx-5 my-2" />

                <template v-for="room of plan.rooms_list">
                    <v-chip class="mr-2" small label :key="room + 'room'"> {{ getProductRoom(room).label }} </v-chip>
                </template>
            </v-card-text>

            <v-card-actions style="min-height: 44px">
                <div>
                    <v-menu open-on-hover offset-x>
                        <template v-slot:activator="{ on }">
                            <v-chip v-if="plan.programs.length > 0" :class="{ 'mb-2': plan.sales.length > 0 }" small label v-on="on">
                                {{ plan.programs.length }} {{ plan.programs.length | plural('programme','programmes') }}
                            </v-chip>
                        </template>

                        <v-card>
                            <v-list>
                                <v-list-item v-for="program of plan.programs_list" :key="program.id">
                                    <v-list-item-avatar>
                                        <v-img :src="program.photo" />
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span v-if="program.title">
                                                {{ program.title }}
                                            </span>
                                            <span v-else>
                                                <i> Sans nom </i>
                                            </span>
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            <span v-if="program.city">
                                                {{ program.city }}
                                            </span>

                                            <span v-if="program.zip">
                                                ({{ program.zip }})
                                            </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>

                    <br v-if="plan.programs.length > 0 && plan.sales.length > 0">

                    <v-chip v-if="plan.assets.length > 0" small label class="ml-2">
                        {{ plan.assets.length }}
                        <v-icon x-small right> fas fa-file </v-icon>
                    </v-chip>
                </div>

                <v-spacer />

                <v-sheet v-show="hover" class="rounded" color="white">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn class="mr-1" color="accent" small icon v-on="{...tooltip }">
                                <v-icon small> fas fa-clone </v-icon>
                            </v-btn>
                        </template>
                        Dupliquer
                    </v-tooltip>

                    <SOTUpdatePlanDialog :plan="plan" @updated="$emit('reload')">
                        <template v-slot:activator="{ on: dialog }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn class="mr-1" color="app-blue" small icon v-on="{ ...dialog, ...tooltip }">
                                        <v-icon small> fas fa-edit </v-icon>
                                    </v-btn>
                                </template>
                                Modifier
                            </v-tooltip>
                        </template>
                    </SOTUpdatePlanDialog>

                    <SOTDeletePlanDialog :planId="plan.id" @deleted="$emit('reload')">
                        <template v-slot:activator="{ on: dialog }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="red" small icon v-on="{ ...dialog, ...tooltip }">
                                        <v-icon small> fas fa-trash-alt </v-icon>
                                    </v-btn>
                                </template>
                                Supprimer
                            </v-tooltip>
                        </template>
                    </SOTDeletePlanDialog>
                </v-sheet>
            </v-card-actions>
        </v-card>
    </v-hover>
</template>

<script>
import products from '../../../mixins/products.js';
import SOTDeletePlanDialog from './SOTDeletePlanDialog.vue';
import SOTUpdatePlanDialog from './SOTUpdatePlanDialog.vue';

export default {
    name: 'SOTPlan',

    components: {
        SOTDeletePlanDialog,
        SOTUpdatePlanDialog
    },

    mixins: [products],

    props: {
        plan: { type: Object, required: true }
    }
};
</script>

<style lang="scss" scoped>
.text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>