<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Création d'un article
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <ValidationObserver ref="observer">
                    <v-row>
                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Nom
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Nom" rules="required">
                                        <v-text-field v-model="item.label" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Référence
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Référence" rules="">
                                        <v-text-field v-model="item.reference" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Prix
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Prix" rules="required">
                                        <v-text-field v-model="item.baseprice" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Unité
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Unité" rules="">
                                        <v-text-field v-model="item.unit" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    TVA
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="TVA" rules="required">
                                        <v-text-field v-model="item.vat" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Couleur
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Couleurs" rules="">
                                        <v-text-field v-model="item.colors" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>

                            <v-row align="center">
                                <v-col cols="3">
                                    Categorie
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Categorie" rules="">
                                        <v-text-field v-model="item.category" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Dimensions
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Dimensions" rules="">
                                        <v-text-field v-model="item.dimensions" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Marque
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Marque" rules="">
                                        <v-text-field v-model="item.brand" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col>

                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col>
                            Description
                            <ValidationProvider v-slot="{ errors, failed }" name="Description" rules="">
                                <v-textarea v-model="item.description" :error-messages="errors" :hide-details="!failed" outlined dense />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'SOTAddItemDialog',

    data: () => ({
        isOpen: false,
        step: 1,
        item: {}
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.item = {};
            }
        }
    },

    methods: {
        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = { item: this.item };

                const { success, err } = await this.repos.sot.createSOTItem(body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('created');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création de l\'article',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>