<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Nouvelle modèle de lot
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <p>
                    Définissez ici un nouveau modèle de lot prédéfini avec les différents types de pièces qui le composent, décrits parmi les modèles de pièces prédéfinis dans l'onglet Modèles de pièces.
                </p>

                <ValidationObserver ref="observer">
                    <v-row align="center">
                        <v-col cols="2">
                            Libellé

                            <span class="red--text"> * </span>
                        </v-col>

                        <v-col>
                            <ValidationProvider v-slot="{ errors, failed }" name="Programmes" rules="required">
                                <v-text-field v-model="productLine.label" :error-messages="errors" :hide-details="!failed" outlined dense />
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="2">
                            Modèle de pièce
                        </v-col>

                        <v-col class="d-flex align-center">
                            <v-select v-model="productLine.category" :items="['Chambre simple']" class="flex-grow-0 mr-2" hint="Quels sont les modèles de pièces à personnaliser pour ce modèle de lot?" :menu-props="{ bottom: true, offsetY: true }" persistent-hint outlined dense />

                            <v-btn class="flex-grow-0 mr-2 align-self-start" color="primary" small> Ajouter </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-chip class="mr-2" label small>
                                Cuisine ouverte
                                <v-icon x-small right> fas fa-times-circle </v-icon>
                            </v-chip>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="2">
                            Typologie
                        </v-col>

                        <v-col class="d-flex align-center">
                            <v-select v-model="productLine.category" :items="['T1', 'T2', 'T3', 'T4']" class="flex-grow-0 mr-2" hint="A quelle typologie de lot correspond ce modèle de lot?" :menu-props="{ bottom: true, offsetY: true }" persistent-hint outlined dense />

                            <v-checkbox class="flex-grow-0 mr-2 align-self-start" label="Ce modèle de lot est le modèle standard pour cette typologie" dense> </v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            Indications acquéreurs
                        </v-col>

                        <v-col>
                            <v-textarea v-model="productLine.description" rows="2" hint="Quelles sont les informations complémentaires éventuelles à indiquer aux acquéreurs à propos de ce modèle de lot?" persistent-hint outlined dense />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            Description
                        </v-col>

                        <v-col>
                            <v-textarea v-model="productLine.description" rows="2" hint="Quelles sont les précisions complémentaires éventuelles à partager en interne à propos de ce modèle de lot?" persistent-hint outlined dense />
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'SOTProductLineProductModelAddDialog',

    data: () => ({
        isOpen: false,
        productLine: {}
    }),

    methods: {
        async submit() {

        }
    }
};
</script>