<template>
    <div class="ma-4">
        Définissez ici des ensembles de choix possibles pour une personnalisation. <br>
        Ces ensembles de choix prédéfinis sont ensuite associés à des types de pièces.

        <div class="d-flex my-2">
            <v-text-field class="flex-grow-0 mr-2" label="Recherche" outlined hide-details dense>
                <template v-slot:prepend-inner>
                    <v-icon left> fas fa-search </v-icon>
                </template>
            </v-text-field>

            <v-select class="flex-grow-0 mr-2" label="Pièces" outlined hide-details dense />

            <v-select class="flex-grow-0 mr-2" label="Catégories" outlined hide-details dense />

            <v-spacer />

            <SOTProductLinePrestationAddDialog>
                <template v-slot:activator="{ on }">
                    <v-btn class="mr-2" color="app-blue" depressed small dark v-on="on">
                        <v-icon x-small left> fas fa-plus </v-icon>
                        Ajouter
                    </v-btn>
                </template>
            </SOTProductLinePrestationAddDialog>
        </div>

        <v-row>
            <v-col cols="2" v-for="(prestation, i) of prestations" :key="i">
                <v-hover v-slot="{ hover }">
                    <v-card class="d-flex flex-column fill-height">
                        <v-card-title class="d-flex flex-nowrap text-h6 py-2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span class="card-title" v-on="on">
                                        {{ prestation.label }}
                                    </span>
                                </template>
                                {{ prestation.label }}
                            </v-tooltip>

                            <v-icon v-if="prestation.checked" color="app-green" small> fas fa-check </v-icon>
                        </v-card-title>

                        <v-divider />

                        <v-card-title>
                            <v-chip color="accent" label small>
                                {{ prestation.count }}
                            </v-chip>

                            <v-spacer />

                            {{ prestation.priceRange.min }} - {{ prestation.priceRange.max }}
                            <template v-if="prestation.priceType === 'simple'">
                                €
                            </template>
                            <template v-else-if="prestation.priceType === 'm2'">
                                € / m²
                            </template>
                        </v-card-title>

                        <v-card-text class="flex-grow-1">
                            <v-chip v-for="(room, i) of prestation.rooms" :key="i" class="mr-2 mb-2" outlined color="primary" label small>
                                {{ room }}
                            </v-chip>
                        </v-card-text>

                        <v-card-actions>
                            <v-chip color="app-red" label dark> {{ prestation.type }} </v-chip>

                            <v-spacer />

                            <v-sheet v-show="hover" class="rounded" color="white">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn class="mr-1" color="accent" small icon v-on="{...tooltip }">
                                            <v-icon small> fas fa-clone </v-icon>
                                        </v-btn>
                                    </template>
                                    Dupliquer
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn class="mr-1" color="app-blue" small icon v-on="{...tooltip }">
                                            <v-icon small> fas fa-edit </v-icon>
                                        </v-btn>
                                    </template>
                                    Modifier
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn class="mr-1" color="red" small icon v-on="{...tooltip }">
                                            <v-icon small> fas fa-trash-alt </v-icon>
                                        </v-btn>
                                    </template>
                                    Supprimer
                                </v-tooltip>
                            </v-sheet>
                        </v-card-actions>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import SOTProductLinePrestationAddDialog from './SOTProductLinePrestationAddDialog.vue';

export default {
    name: 'SOTProductLinePrestations',

    props: {
        productLine: { type: Object, required: true }
    },

    components: {
        SOTProductLinePrestationAddDialog
    },

    data: () => ({
        prestations: [
            {
                label: 'Radiateurs humides',
                checked: true,
                count: 3,
                priceRange: { min: 0, max: 250 },
                priceType: 'simple',
                rooms: ['Salle de bain', 'Salle d\'eau', 'Cuisine'],
                type: 'Chauffage'
            },
            {
                label: 'Radiateurs pièces à vivre',
                checked: false,
                count: 5,
                priceRange: { min: 0, max: 400 },
                priceType: 'simple',
                rooms: ['Salon', 'Chambre', 'Cuisine'],
                type: 'Chauffage'
            },
            {
                label: 'Sols moquette',
                checked: false,
                count: 3,
                priceRange: { min: 0, max: 10 },
                priceType: 'm2',
                rooms: ['Salle de bain', 'Salle d\'eau', 'Cuisine'],
                type: 'Chauffage'
            }
        ]
    })
};
</script>

<style lang="scss" scoped>
.card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>