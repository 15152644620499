export default {
    methods: {
        getSOTItemAssetImages(assets) {
            if (Array.isArray(assets)) {
                return assets.filter((a) => a.type === 0);
            } else {
                return [];
            }
        },

        getSOTItemAssetYoutubeLinks(assets) {
            if (Array.isArray(assets)) {
                return assets.filter((a) => a.type === 2 && a.url.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/));
            } else {
                return [];
            }
        },

        getSOTItemAssetVideoLinks(assets) {
            if (Array.isArray(assets)) {
                return assets.filter((a) => a.type === 2 && !a.url.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/));
            } else {
                return [];
            }
        },

        getSOTItemAssetDocuments(assets) {
            if (Array.isArray(assets)) {
                return assets.filter((a) => a.type === 1);
            } else {
                return [];
            }
        },

        getSOTItemAssetUrl(asset) {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/sot/items/assets/${asset.id}`;
        },

        getSOTPlanAlternativeAssetUrl(asset) {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/sot/planAlternatives/assets/${asset.id}`;
        }
    }
};



