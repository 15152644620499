<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Nouvelle alternative TMA
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <ValidationObserver ref="observer">
                    <v-row>
                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Libellé
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Libellé" rules="required">
                                        <v-text-field v-model="plan.label" :error-messages="errors" :hide-details="!failed" outlined dense />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Montant TTC
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="Montant TTC" rules="required|number">
                                        <v-text-field v-model="plan.baseprice" :error-messages="errors" :hide-details="!failed" outlined dense append-icon="fas fa-euro-sign" />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Programmes
                                </v-col>

                                <v-col>
                                    <ProgramAutocomplete v-model="plan.programs" multiple />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    TVA
                                </v-col>

                                <v-col>
                                    <ValidationProvider v-slot="{ errors, failed }" name="TVA" rules="required|number">
                                        <VATSelect v-model="plan.vat" label="" :error-messages="errors" :hide-details="!failed" />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    <span class="white-space-no-wrap"> Typologies </span>
                                </v-col>

                                <v-col>
                                    <ProductRoomsSelect v-model="plan.rooms_list" multiple />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col>
                            <v-row align="center">
                                <v-col cols="3">
                                    Travaux
                                </v-col>

                                <v-col>
                                    <div class="d-flex align-center">
                                        <v-text-field v-model="newItem" class="flex-grow-0 mr-2" @keyup.enter="addItem()" hide-details outlined dense />

                                        <v-btn @click="addItem()" small> Ajouter </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-chip-group column>
                                <v-chip v-for="item of plan.items" :key="item">
                                    {{ item }}
                                    <v-btn @click="removeItem(item)" x-small icon>
                                        <v-icon icon x-small> fas fa-times </v-icon>
                                    </v-btn>
                                </v-chip>
                            </v-chip-group>
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import VATSelect from '../../widgets/VATSelect.vue';
import ProductRoomsSelect from '../../widgets/ProductRoomsSelect.vue';
import ProgramAutocomplete from '../../widgets/ProgramAutocomplete.vue';

export default {
    name: 'SOTAddPlanDialog',

    components: {
        VATSelect,
        ProductRoomsSelect,
        ProgramAutocomplete
    },

    data: () => ({
        isOpen: false,
        newItem: '',
        plan: {
            items: []
        }
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.plan = { items: [] };
            }
        }
    },

    methods: {
        addItem() {
            if (!this.plan.items.includes(this.newItem)) {
                this.plan.items.push(this.newItem);
            }
            this.newItem = '';
        },

        removeItem(item) {
            this.plan.items = this.plan.items.filter((i) => i !== item);
        },

        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                if (typeof this.plan.baseprice === 'string') {
                    this.plan.baseprice = this.plan.baseprice.replace(',', '.').replace(' ', '');
                }
                if (typeof this.plan.vat === 'string') {
                    this.plan.vat = this.plan.vat.replace(',', '.').replace(' ', '');
                }

                const body = { plan: this.plan };

                const { success, err } = await this.repos.sot.createSOTPlanAlternative(body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('created');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création de l\'article',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>