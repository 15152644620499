<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Nouvelle prestation
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <p>
                    Définissez ici un nouvel ensemble de choix possibles pour une personnalisation dans une catégorie de prestations, pour certains types de pièces.
                </p>

                <ValidationObserver ref="observer">
                    <v-row align="center">
                        <v-col cols="2">
                            Libellé

                            <span class="red--text"> * </span>
                        </v-col>

                        <v-col>
                            <ValidationProvider v-slot="{ errors, failed }" name="Programmes" rules="required">
                                <v-text-field v-model="productLine.label" :error-messages="errors" :hide-details="!failed" outlined dense />
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="2">
                            Catégorie

                            <span class="red--text"> * </span>
                        </v-col>

                        <v-col>
                            <ValidationProvider v-slot="{ errors }" name="Catégorie" rules="required">
                                <v-select v-model="productLine.category" :error-messages="errors" hint="Quelle est la catégorie des articles du catalogue à regrouper dans cet ensemble de choix?" persistent-hint outlined dense />
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="2">
                            Article
                        </v-col>

                        <v-col class="d-flex align-center">
                            <v-select v-model="productLine.category" class="flex-grow-0 mr-2" hint="Quels sont les articles du catalogue proposés dans cet ensemble de choix?" persistent-hint outlined dense />

                            <v-btn class="flex-grow-0 mr-2 align-self-start" color="primary" small> Ajouter </v-btn>

                            <v-checkbox class="flex-grow-0 align-self-start mt-0" label="Choix par défaut"></v-checkbox>

                            <div class="align-self-start">
                                <span class="white-space-no-wrap">
                                    2 articles
                                </span> <br>
                                <span class="white-space-no-wrap">
                                    de 0 à 115€
                                </span>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip class="mr-2" label small v-on="on">
                                        <v-icon x-small left> fas fa-check </v-icon>
                                        <b>
                                            RE650-SMG
                                        </b>
                                        <v-icon x-small right> fas fa-times-circle </v-icon>
                                    </v-chip>
                                </template>
                                Radiateur éléctrique 650W - 110 €
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip class="mr-2" label small v-on="on">
                                        RE1000-PS
                                        <v-icon x-small right> fas fa-times-circle </v-icon>
                                    </v-chip>
                                </template>
                                Radiateur éléctrique 650W - 110 €
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <v-row align="center">
                        <v-col cols="2">
                            Type de pièce
                        </v-col>

                        <v-col class="d-flex align-center">
                            <v-select v-model="productLine.category" class="flex-grow-0 mr-2" hint="Dans quel(s) type(s) de pièces cet ensemble de choix peut-il être proposé?" persistent-hint outlined dense />

                            <v-btn class="flex-grow-0 mr-2 align-self-start" color="primary" small> Ajouter </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-chip class="mr-2" label small>
                                Cuisine
                                <v-icon x-small right> fas fa-times-circle </v-icon>
                            </v-chip>

                            <v-chip class="mr-2" label small>
                                Salle de bain
                                <v-icon x-small right> fas fa-times-circle </v-icon>
                            </v-chip>

                            <v-chip class="mr-2" label small>
                                Salle d'eau
                                <v-icon x-small right> fas fa-times-circle </v-icon>
                            </v-chip>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            Indications acquéreurs
                        </v-col>

                        <v-col>
                            <v-textarea v-model="productLine.description" rows="2" hint="Quelles sont les informations complémentaires éventuelles à indiquer aux acquéreurs à propos de cette prestation?" persistent-hint outlined dense />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            Description
                        </v-col>

                        <v-col>
                            <v-textarea v-model="productLine.description" rows="2" hint="Quelles sont les précisions complémentaires éventuelles à partager en interne à propos de cette prestation?" persistent-hint outlined dense />
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'SOTProductLineAddDialog',

    data: () => ({
        isOpen: false,
        productLine: {}
    }),

    methods: {
        async submit() {

        }
    }
};
</script>