<template>
    <div>
        <v-card class="mb-4">
            <v-toolbar flat dense>
                <h3 class="font-weight-regular"> {{ productLine.label }} </h3>

                <v-spacer />

                <v-menu open-on-hover offset-x left>
                    <template v-slot:activator="{ on }">
                        <v-chip label v-on="on">
                            {{ productLine.programs.length }} {{ productLine.programs.length | plural('programme', 'programmes') }}
                        </v-chip>
                    </template>

                    <v-card>
                        <v-list>
                            <v-list-item v-for="program of productLine.programs" :key="program.id">
                                <v-list-item-avatar>
                                    <v-img :src="program.photo" />
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span v-if="program.title">
                                            {{ program.title }}
                                        </span>
                                        <span v-else>
                                            <i> Sans nom </i>
                                        </span>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                        <span v-if="program.city">
                                            {{ program.city }}
                                        </span>

                                        <span v-if="program.zip">
                                            ({{ program.zip }})
                                        </span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>

                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn class="mr-1" color="app-blue" small icon v-on="{...tooltip }">
                            <v-icon small> fas fa-edit </v-icon>
                        </v-btn>
                    </template>
                    Modifier
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn class="mr-1" color="red" small icon v-on="{...tooltip }">
                            <v-icon small> fas fa-trash-alt </v-icon>
                        </v-btn>
                    </template>
                    Supprimer
                </v-tooltip>
            </v-toolbar>

            <h5 class="font-weight-light px-4"> {{ productLine.description }} </h5>

            <v-tabs class="mb-2 rounded" v-model="tab">
                <v-tab> Prestations </v-tab>
                <v-tab> Modèles de pièces </v-tab>
                <v-tab> Modèles de lots </v-tab>
            </v-tabs>
        </v-card>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <SOTProductLinePrestations :productLine="productLine" />
            </v-tab-item>

            <v-tab-item>
                <SOTProductLineRoomModels :productLine="productLine" />
            </v-tab-item>

            <v-tab-item>
                <SOTProductLineProductModels :productLine="productLine" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>


<script>
import SOTProductLineRoomModels from './SOTProductLineRoomModels.vue';
import SOTProductLinePrestations from './SOTProductLinePrestations.vue';
import SOTProductLineProductModels from './SOTProductLineProductModels.vue';

export default {
    name: 'SOTProductLine',

    props: {
        productLine: { type: Object, required: true }
    },

    components: {
        SOTProductLineRoomModels,
        SOTProductLinePrestations,
        SOTProductLineProductModels
    },

    data: () => ({
        tab: 0
    })
};
</script>