<template>
    <v-hover v-slot="{ hover }">
        <v-card>
            <v-row no-gutters>
                <v-col>
                    <v-chip class="mt-3 ml-3" color="app-blue" outlined dark label small> {{ item.category }} </v-chip>
                </v-col>

                <v-col>
                    <span class="mt-3 mr-3 float-right text-h5 font-weight-bold"> {{ item.baseprice | toCurrencyString() }} </span>
                </v-col>
            </v-row>

            <v-carousel show-arrows-on-hover hide-delimiters height="180px">
                <v-carousel-item v-for="(asset, i) of getSOTItemAssetImages(item.assets)" :key="i">
                    <v-img :src="getSOTItemAssetUrl(asset)" contain height="100%">

                    </v-img>
                </v-carousel-item>
            </v-carousel>

            <v-card-title>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <span class="card-title" v-on="on">
                            {{ item.label }}
                        </span>
                    </template>
                    {{ item.label }}
                </v-tooltip>
            </v-card-title>

            <v-card-subtitle class="d-flex align-center" style="min-height: 44px">
                {{ item.reference }}

                <v-spacer />

                <v-sheet v-show="hover" class="rounded" color="white">
                    <SOTCloneItemDialog :itemId="item.id" @cloned="$emit('reload')">
                        <template v-slot:activator="{ on: dialog }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn class="mr-1" color="accent" small icon v-on="{ ...dialog, ...tooltip }">
                                        <v-icon small> fas fa-clone </v-icon>
                                    </v-btn>
                                </template>
                                Dupliquer
                            </v-tooltip>
                        </template>
                    </SOTCloneItemDialog>

                    <SOTUpdateItemDialog :item="item" @updated="$emit('reload')">
                        <template v-slot:activator="{ on: dialog }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn class="mr-1" color="app-blue" small icon v-on="{ ...dialog, ...tooltip }">
                                        <v-icon small> fas fa-edit </v-icon>
                                    </v-btn>
                                </template>
                                Modifier
                            </v-tooltip>
                        </template>
                    </SOTUpdateItemDialog>

                    <SOTDeleteItemDialog :itemId="item.id" @deleted="$emit('reload')">
                        <template v-slot:activator="{ on: dialog }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn class="mr-1" color="red" small icon v-on="{ ...dialog, ...tooltip }">
                                        <v-icon small> fas fa-trash-alt </v-icon>
                                    </v-btn>
                                </template>
                                Supprimer
                            </v-tooltip>
                        </template>
                    </SOTDeleteItemDialog>
                </v-sheet>
            </v-card-subtitle>
        </v-card>
    </v-hover>
</template>

<script>
import sotMixin from '../../../mixins/sot.js';
import SOTCloneItemDialog from './SOTCloneItemDialog.vue';
import SOTDeleteItemDialog from './SOTDeleteItemDialog.vue';
import SOTUpdateItemDialog from './SOTUpdateItemDialog.vue';

export default {
    name: 'SOTItem',

    props: {
        item: { type: Object, required: true }
    },

    mixins: [sotMixin],

    components: {
        SOTCloneItemDialog,
        SOTDeleteItemDialog,
        SOTUpdateItemDialog
    }
};
</script>

<style lang="scss" scoped>
.card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>