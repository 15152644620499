var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-chip',{staticClass:"mt-3 ml-3",attrs:{"color":"app-blue","outlined":"","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.item.category)+" ")])],1),_c('v-col',[_c('span',{staticClass:"mt-3 mr-3 float-right text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrencyString")(_vm.item.baseprice))+" ")])])],1),_c('v-carousel',{attrs:{"show-arrows-on-hover":"","hide-delimiters":"","height":"180px"}},_vm._l((_vm.getSOTItemAssetImages(_vm.item.assets)),function(asset,i){return _c('v-carousel-item',{key:i},[_c('v-img',{attrs:{"src":_vm.getSOTItemAssetUrl(asset),"contain":"","height":"100%"}})],1)}),1),_c('v-card-title',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"card-title"},on),[_vm._v(" "+_vm._s(_vm.item.label)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.item.label)+" ")])],1),_c('v-card-subtitle',{staticClass:"d-flex align-center",staticStyle:{"min-height":"44px"}},[_vm._v(" "+_vm._s(_vm.item.reference)+" "),_c('v-spacer'),_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"rounded",attrs:{"color":"white"}},[_c('SOTCloneItemDialog',{attrs:{"itemId":_vm.item.id},on:{"cloned":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"accent","small":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-clone ")])],1)]}}],null,true)},[_vm._v(" Dupliquer ")])]}}],null,true)}),_c('SOTUpdateItemDialog',{attrs:{"item":_vm.item},on:{"updated":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"app-blue","small":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Modifier ")])]}}],null,true)}),_c('SOTDeleteItemDialog',{attrs:{"itemId":_vm.item.id},on:{"deleted":function($event){return _vm.$emit('reload')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"red","small":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash-alt ")])],1)]}}],null,true)},[_vm._v(" Supprimer ")])]}}],null,true)})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }