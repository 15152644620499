<template>
    <div>
        <v-row>
            <v-col>
                <p>
                    Définissez ici la liste exhaustive des plans alternatifs proposés en Travaux Modificatifs prédéfinis aux acquéreurs; Ceci permet aux acquéreurs de choisir si nécessaire des configurations alternatives standardisés et chiffrées au lieu de "redessiner" eux-même le plan de leur logement sans idée des devis induits.
                </p>
            </v-col>
        </v-row>

        <v-card class="d-flex align-center mb-4 pa-2">
            <v-text-field v-model="quickSearch" class="flex-grow-0 mr-2" label="Recherche" color="app-blue" prepend-inner-icon="fas fa-search" outlined hide-details dense />

            <ProgramAutocomplete v-model="programsFilter" label="Programmes" class="mr-2" multiple flexGrow0 />
            <ProductRoomsSelect v-model="roomsFilter" label="Typologie" class="mr-2" multiple flexGrow0 />

            <v-spacer />

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-chip class="mr-2" label small v-on="on"> {{ plansTotalCount }} </v-chip>
                </template>
                Nombre total d'alternatives TMA
            </v-tooltip>

            <SOTAddPlanDialog @created="fetchPlans()">
                <template v-slot:activator="{ on }">
                    <v-btn color="app-blue" depressed small dark v-on="on">
                        <v-icon x-small left> fas fa-plus </v-icon>
                        Ajouter
                    </v-btn>
                </template>
            </SOTAddPlanDialog>

            <v-pagination v-model="page" @input="fetchPlans()" :length="plansTotalPages" color="app-blue" />
        </v-card>

        <div class="d-flex flex-wrap mb-1">
            <SOTPlan v-for="plan of plans" :key="plan.id" :plan="plan" @reload="fetchPlans()" class="mr-2 mb-2" />
        </div>
    </div>
</template>

<script>
import SOTPlan from './SOTPlan.vue';
import SOTAddPlanDialog from './SOTAddPlanDialog.vue';
import ProductRoomsSelect from '../../widgets/ProductRoomsSelect.vue';
import ProgramAutocomplete from '../../widgets/ProgramAutocomplete.vue';


export default {
    name: 'SOTPlanAlternatives',

    components: {
        SOTPlan,
        SOTAddPlanDialog,
        ProductRoomsSelect,
        ProgramAutocomplete
    },

    data: () => ({
        plans: [],
        plansTotalCount: 0,
        plansTotalPages: 1,

        page: 1,
        limit: 21,
        quickSearch: null,
        programsFilter: null,
        roomsFilter: null
    }),

    watch: {
        quickSearch() {
            this.doSearch();
        }
    },

    methods: {
        doSearch() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.fetchPlans();
            }, 500);
        },

        async fetchPlans() {
            try {
                this.setLoading(true);

                const { plans, count, totalPages, err } = await this.repos.sot.getSOTPlanAlternatives(this.query);
                if (err) {
                    throw new Error(err);
                }
                this.plans = plans;
                this.plansTotalCount = count;
                this.plansTotalPages = totalPages;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement du catalogue tma',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    computed: {
        query() {
            const orderBy = 'label:asc';
            const query = {
                page: this.page,
                limit: this.limit,
                orderBy,
                include: 'programs,sales,assets'
            };

            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }

            return query;
        }
    },

    created() {
        this.fetchPlans();
    }
};
</script>